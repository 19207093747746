@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
body {
	margin: 0;
	font-family: "Special Elite";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-image: url(/static/media/gwot-traplord.32193624.jpg);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.link:focus {
	outline: none;
}

